<template>
	<select
		:value="selection"
		:class="[
			'w-full appearance-none bg-white p-2.5 text-sm md:text-base',
			'focus-visible:rounded-sm focus-visible:ring-1 focus-visible:ring-cta',
		]"
		:style="backgroundStyle"
		:aria-label="aria"
		@change="emit('changeOption', ($event.target as HTMLSelectElement).value)"
	>
		<option v-for="option in options" :key="option" :value="option">
			{{ option }}
		</option>
	</select>
</template>

<script setup lang="ts">
import type { StyleValue } from 'vue';

interface Props {
	options: string[] | Set<string>;
	selection?: string;
	aria: string;
}

withDefaults(defineProps<Props>(), { selection: '' });

const emit = defineEmits<{
	(event: 'changeOption', value: string): void;
}>();

const backgroundStyle = computed<StyleValue>(() => ({
	backgroundImage:
		'url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJhIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTEyLDE1Yy0uMjEsMC0uNDEtLjA5LS41NS0uMjRsLTMuMjUtMy41Yy0uMjgtLjMtLjI2LS43OCwuMDQtMS4wNiwuMy0uMjgsLjc4LS4yNiwxLjA2LC4wNGwyLjcsMi45MSwyLjctMi45MWMuMjgtLjMsLjc2LS4zMiwxLjA2LS4wNCwuMywuMjgsLjMyLC43NiwuMDQsMS4wNmwtMy4yNSwzLjVjLS4xNCwuMTUtLjM0LC4yNC0uNTUsLjI0WiIvPjwvc3ZnPg==")',
	backgroundSize: '32px',
	backgroundPosition: '95% 50%',
	backgroundRepeat: 'no-repeat',
}));
</script>
